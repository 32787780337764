<template>
  <div>
     <fieldsRows
      :templateContent="templateContent"
      @field-value-changed="updateFieldValue(...arguments)"
      :valueLine="localStoredValue"
      :isValid="isValid"
      v-on="$listeners"
      @type-changed="changeTargetType"
    ></fieldsRows>
    <explorerOnError
      v-model="onErrorVal"
      :result="{
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        enableAdd: false,
        contentID: this.stepType + '_' + this.stepID,
      }"
    ></explorerOnError>
    <explorerCollectStatistics
        v-model="statistic"
        :result="result"
    >
    </explorerCollectStatistics>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import teststepDefault from "@/cfg/teststep-default.json";
import lineButtons from "@/cfg/lineButtons.json";
import options from "@/cfg/options.json";
import explorerOnError from "@/components/dynamic/explorerOnError.vue";
import explorerCollectStatistics from "@/components/legacy/explorerCollectStatistics.vue";

export default {
  components: {
    fieldsRows,
    explorerOnError,
    explorerCollectStatistics,
  },
  data() {
    return {
      options: options,
    };
  },
  props: ["result", "stepStoredValue", "stepType", "stepID", "isValid"],
  computed: {
    statistic: {
      get() {
        return this.stepStoredValue.statistic;
      },
      set(newVal) {
        console.log(newVal);
        this.$set(this.stepStoredValue, "statistic", newVal);
        this.$emit("input", this.stepStoredValue);
      },
    },
    fields() {
      let fields = {
        type: {
          type: "combobox",
          associatedOption: this.result.additional.explorerEvalType,
          name: "Type",
          fieldAttrInput: { class: "required" },
        },
        operator: {
          type: "select",
          associatedOption: this.result.additional.explorerEvalOperator,
          name: "Operator",
          fieldAttrInput: { class: "required" },
        },
        content: {
          inputFieldFormatter: "udpEvalValue",
          AvgMaxNumber: 100,
        },
        else: {
          input: false,
          type: "string",
          string: "else",
          colAttrs: {
            style: "max-width: 50px;",
          },
        },
        result: {
          type: "select",
          associatedOption: options.ldapResult,
          name: "Result",
          fieldAttrInput: { class: "required" },
          default: "error",
        },
        action: {
          type: "select",
          associatedOption: options.evalAction,
          name: "Action",
          default: "stopTest",
          fieldAttrInput: { class: "required" },
        },
        remove: lineButtons.remove,
      };
      return fields;
    },
    localStoredValue: {
      get() {
        // console.log(stepStoredValue);
        let stepStoredValue = this.stepStoredValue;
        if (typeof stepStoredValue.startWithinMinutes == "undefined") {
          stepStoredValue = JSON.parse(
            JSON.stringify(teststepDefault[this.stepType])
          );
          this.$emit("stored-value-changed", stepStoredValue);
        }
        if (typeof stepStoredValue.parameters.target.host == "undefined") {
          this.$set(
            stepStoredValue.parameters.target,
            "host",
            teststepDefault[this.stepType].parameters.target.host
          );
          this.$emit("stored-value-changed", stepStoredValue);
        }
        return stepStoredValue;
      },
      set(newVal) {
        this.$emit("stored-value-changed", newVal);
      },
    },
    templateContent() {
      let templateContent = {
        fields: {
          timeLine: {
            type: "line",
            fields: {
              startWithinMinutes: {
                type: "number",
                name: "Start Within Minutes",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: { style: "max-width:170px", class: "" },
              },
              finishedWithinMinutes: {
                type: "number",
                name: "Finished Within Minutes",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: { style: "max-width:170px", class: "" },
              },
              maxDuration: {
                type: "number",
                name: "Maximum Duration (s)",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: { style: "max-width:170px", class: "" },
              },
            },
          },
          parameters: {
            inputFieldFormatter: "explorerSIP",
          },
          res: {
            type: "text",
            inputFieldFormatter: "sipCallEval",
          },

        },
        audioQuality:this.localStoredValue.parameters.checkAudioQuality,
        codecs: this.result.additional.codecs,
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        enableAdd: false,
       //contentID: this.stepType + "_" + this.stepID,
        contentID: this.stepType,
      };
      return templateContent;
    },
    onErrorVal: {
      get() {
        return this.stepStoredValue.res;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  methods: {
    changeTargetType(value) {
      this.$set(this.stepStoredValue.parameters.target, "type", value);
      this.$emit("stored-value-changed", this.stepStoredValue);
    },
    updateFieldValue(field, value) {
      //console.log("CORE", field, value);
      let localStoredStep = { ...this.stepStoredValue };
      if (field != "parameters" && field != "testDirection") {
        this.$set(localStoredStep, field, value);
        this.$emit("stored-value-changed", localStoredStep);
      }
    },
  },
};
</script>